.footer {
  background-color: #111111;
  color: #ffffff;
  padding: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left-Footer {
  text-align: left;
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.right-Footer {
  text-align: right;
  width: 50%;
}

.left-Footer > div {
  display: flex;
  align-items: center;
}

.left-Footer > div > h2 {
  color: #ffffff;
  display: inline;
  padding-left: 10px;
}

.right-Footer > a > h3 {
  color: #ffffff;
}
.right-Footer > a > h3:hover {
  color: #fe6857;
}

.mobileFooter {
  text-align: center;
  display: none;
  width: 100%;
}

.mobileFooter > a > h3 {
  text-decoration: none;
  color: #ffffff;
}

.mobileFooter > a > h3:hover {
  color: #fe6857;
}

@media (max-width: 800px) {
  .mobileFooter {
    display: inline-block;
  }

  .footer {
    padding: 2rem;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .right-Footer {
    display: none;
  }

  .left-Footer {
    display: none;
  }
}
