@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -o-font-smoothing: antialiased !important;
  padding: 0;
  margin: 0;
}

h1 {
  font-family: "Raleway";
  color: var(--black-color);
  font-size: 3.5em;
  margin: 0;
  font-weight: 600;
}

h2 {
  font-family: "Raleway";
  color: var(--black-color);
  margin: 0;
  font-size: 3em;
  font-weight: 600;
}

h3 {
  font-family: "Raleway";
  color: var(--black-color);
  font-size: 1.6em;
  font-weight: 600;
}
h4 {
  font-family: "Raleway";
  color: var(--black-color);
  font-weight: 600;
}

p {
  font-family: "Raleway";
  font-size: 1.1em;
  line-height: 1.4em;
}

/* BUTTON */

button {
  font-family: "Raleway";
  border-radius: 50px;
  border: 0;
  letter-spacing: 2px;
  background: #fe6857;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 0.9em;
  transition: 0.2s ease-in-out;
  height: 40px;
  color: #000000;
}

button:hover {
  background: #000000;
  color: #ffffff;
  cursor: pointer;
}

.accent {
  color: #fe6857;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: #000000;
}

.hidden {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.6s;
}

.hidden2 {
  opacity: 0;
  transform: translateX(20px);
  transition: all 0.8s;
}

.hidden3 {
  opacity: 0;
  transition: all 0.8s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fe6857 !important;
}

@media (max-width: 600px) {
  h1 {
    font-size: 2.8em;
  }
  h2 {
    font-size: 2.8em;
  }
}
