/* OUR WORK */
#ourwork {
  padding: 80px;
}

.workWrapper {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.workPanel {
  width: 33%;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.1);
  transition-duration: 0.2s;
}

.workText {
  padding-left: 20px;
}

/* Extra large devices (≥1200px) */
@media (max-width: 1100px) {
  #ourwork {
    padding: 2rem;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* Extra large devices (≥1200px) */
@media (max-width: 1100px) {
  #ourwork {
    padding: 2rem;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 800px) {
  .workWrapper {
    flex-wrap: wrap;
  }

  .workPanel {
    width: 100%;
  }
}
