/* ABOUT */
#about {
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 100px;
}

.aboutWrapper {
  display: flex;
  align-items: center;
}

.right-about {
  width: 50%;
  padding-left: 40px;
}

.left-about {
  width: 50%;
  text-align: left;
}

.left-about > p {
  font-size: 1.1em;
  line-height: 1.4em;
  padding: 0;
}

.aboutPanel {
  color: #ffffff;
  font-family: Raleway-Medium;
  width: 140px;
  height: 140px;
  background-color: #fe6857;
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
}

.aboutPanel > h1 {
  color: #ffffff;
}

.mobileAbout {
  display: none;
  text-align: left;
}

.iFrameWrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 1200px) {
  .aboutWrapper {
    display: none;
  }
  .mobileAbout {
    display: inline-block;
  }
}

/* Extra large devices (≥1200px) */
@media (max-width: 1100px) {
  #about {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 50px;
  }
}
