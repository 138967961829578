.header {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.navbar {
  display: inline;
  font-family: "Raleway";
  letter-spacing: 3px;
  gap: 40px;
  align-items: center;
  font-size: 1em;
  color: var(--black-color);
  display: flex;
}

.navbar > a {
  color: #000000;
  font-weight: 500;
}

.left-navbar {
  display: inline-flex;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 1.5em;
  color: var(--black-color);
  text-decoration: none;
  align-items: center;
}

.hamburger-menu {
  display: none;
}

.hamburger-menu:hover {
  cursor: pointer;
}

.headerButton {
  display: inline;
}

.mobileMenu {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  transition: 0.5s ease-in-out;
}

.mobileMenu > a {
  color: #000000;
  text-decoration: none;
}

.mobileMenuItem {
  font-size: 2.8em;
  color: #000000;
  text-decoration: none;
}

/* Extra large devices (≥1200px) */
@media (max-width: 1100px) {
  .header {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* Extra large devices (≥1200px) */
@media (max-width: 900px) {
  .navbar {
    display: none;
  }

  .hamburger-menu {
    display: inline;
    padding-left: 2rem;
  }
}

/* Extra large devices (≥1200px) */
@media (max-width: 700px) {
  .headerButton {
    display: none;
  }
}
