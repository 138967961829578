.getStartedSection {
  width: auto;

  background: rgb(255, 204, 184);
  background: linear-gradient(
    30deg,
    rgba(255, 204, 184, 1) 0%,
    rgba(255, 246, 242, 1) 100%
  );
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 20px;
}

.getStartedSection > a {
  display: inline;
}
.logoWrapper {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  font-family: "Raleway";
  font-size: 1.5em;
  color: #000000;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.bookingWrapper {
  width: auto;
  padding-left: 80px;
  padding-right: 80px;
}

@media (max-width: 1000px) {
  .getStartedSection {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .logoWrapper {
    display: none;
  }
}
