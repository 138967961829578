.formWrapper {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.nextButton {
  font-family: "Raleway";
  border-radius: 50px;
  border: 0;
  letter-spacing: 2px;
  background: #fe6857;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 0.9em;
  transition: 0.2s ease-in-out;
  height: 40px;
}

.backButton {
  font-family: "Raleway";
  border-radius: 50px;
  border: 0;
  letter-spacing: 2px;
  background: #111111;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 0.9em;
  transition: 0.2s ease-in-out;
  height: 40px;
  color: white;
  margin-right: 10px;
}

input {
  border: 0;
  border-bottom: 1px #fe6857 solid;
  background-color: transparent;
  font-size: 1em;
  color: #fe6857;
  font-family: "Raleway";
  padding-bottom: 5px;
  text-align: center;
  width: 400px;
  font-size: 1.6em;
  margin-top: 20px;
}

input:focus {
  outline: 0;
}

.packageWrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  gap: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.packageCard {
  border-radius: 7px;
  width: 400px;
  transition: 0.3s;
  background: white;
  transform: scale(1);
  font-size: 1em;
  height: auto;
}

.packageCard > button {
  font-size: 3em;
}

.packageCard:hover {
  cursor: pointer;
}

.packageCardTop {
  border-radius: 5px 5px 10px 10px;
  border-bottom: #dfdfdf solid 1px;
  width: auto;
  padding-bottom: 30px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 1em;
}

.packageCardBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1em;
}

.sidebar {
  width: 400px;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
  overflow: scroll;
  top: 0;
  right: 0;
  z-index: 1000;
  -webkit-box-shadow: -9px 0px 28px -8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -9px 0px 28px -8px rgba(0, 0, 0, 0.1);
  box-shadow: -9px 0px 28px -8px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out;
}

.cross {
  position: fixed;
  right: 0;
  top: 0;
  border-radius: 0 0 0 10px;
  background-color: #111111;
  padding: 20px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 12px;
  z-index: 10000;
  transition: 0.3s ease-in-out;
}

.cross:hover {
  cursor: pointer;
  background-color: #fe6857;
  color: #ffffff;
}

/* Extra large devices (≥1200px) */
@media (max-width: 1000px) {
  .packageWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .formWrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: 500px) {
  .packageCard {
    width: 100%;
  }

  input {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .sidebar {
    width: 100%;
  }

  .card {
    width: 100%;
  }
}
