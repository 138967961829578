/* PROBLEM */
#problem {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}

.problemCardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 50px;
  row-gap: 0;
  justify-content: center;
}

.problemCard {
  padding: 20px;
  width: 350px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  text-align: left;
}

.iconWrapper {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40px;
  text-align: center;
}

/* Extra large devices (≥1200px) */
@media (max-width: 1100px) {
  #problem {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 1200px) {
  .problemCard {
    width: 45%;
    padding: 10px;
  }
}

@media (max-width: 800px) {
  .problemCard {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
