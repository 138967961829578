/* SOLUTION */
#pricing {
  background: rgb(255, 204, 184);
  background: linear-gradient(
    210deg,
    rgba(255, 204, 184, 1) 0%,
    rgba(255, 246, 242, 1) 100%
  );
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
}

/* CARD */
.cardWrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  border-radius: 5px;
  width: 400px;
  background: white;
}

.cardTop {
  background-color: #fafafa;
  border-radius: 5px 5px 10px 10px;
  border-bottom: #dfdfdf solid 1px;
  width: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.cardMain {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  text-align: left;
  padding-bottom: 20px;
}

/* Extra large devices (≥1200px) */
@media (max-width: 1100px) {
  #pricing {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Extra large devices (≥1200px) */
@media (max-width: 500px) {
  .card {
    width: 100%;
  }
}
