.topSection {
  background: rgb(255, 204, 184);
  background: linear-gradient(
    30deg,
    rgba(255, 204, 184, 1) 0%,
    rgba(255, 246, 242, 1) 100%
  );
  width: auto;
  overflow: hidden;
  padding-bottom: 60px;
}

/* LANDING */
.landingSection {
  background: rgb(255, 204, 184);
  background: linear-gradient(
    30deg,
    rgba(255, 204, 184, 1) 0%,
    rgba(255, 246, 242, 1) 100%
  );
  width: auto;
  height: 100%;
  overflow: hidden;
}

.leftHeadingSection {
  width: 600px;
  padding-right: 80px;
  padding-left: 80px;
}
.headingSection {
  text-align: left;
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 150%;
  padding-bottom: 40px;
}

.landingImage {
  width: 40%;
  display: inline;
}

.mobileLandingImage {
  display: none;
}
.mobileLandingImageWrapper {
  display: none;
}
/* Extra large devices (≥1200px) */
@media (max-width: 1100px) {
  .leftHeadingSection {
    padding-left: 2rem;
  }
}

/* Extra large devices (≥1200px) */
@media (max-width: 1000px) {
  .landingImage {
    display: none;
  }

  .leftHeadingSection {
    width: 100%;
    padding-right: 2rem;
  }

  .headingSection {
    width: 100%;
  }

  .mobileLandingImageWrapper {
    width: 120%;
    display: flex;
    justify-content: start;
    padding-left: 2rem;
  }

  .mobileLandingImage {
    width: 120%;
    display: block;
  }
}
